import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='Entry'>
        <p>
          explore, learn,
        </p>
        <p>
           empower  yourself
        </p>
        </div>
        <div className='Cont'>
          <div>

          </div>
          <p>
            continue
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
